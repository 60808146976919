import { BoardCommonControllerService } from '@/__generated__/CommonApi';
import { CorporateControllerService, CsrVo } from '@/__generated__/FrontApi';
import Processes1 from '@/assets/img/icons/ico_philanthropie_processes1@2x.png';
import Processes2 from '@/assets/img/icons/ico_philanthropie_processes2@2x.png';
import Processes3 from '@/assets/img/icons/ico_philanthropie_processes3@2x.png';
import Processes4 from '@/assets/img/icons/ico_philanthropie_processes4@2x.png';
import Processes5 from '@/assets/img/icons/ico_philanthropie_processes5@2x.png';
import Processes6 from '@/assets/img/icons/ico_philanthropie_processes6@2x.png';
import partnership1 from '@/assets/img/partnership_img1@2x.jpg';
import partnership2 from '@/assets/img/partnership_img2@2x.jpg';
import partnership3 from '@/assets/img/partnership_img3@2x.jpg';
import partnership4 from '@/assets/img/partnership_img4@2x.jpg';
import partnership5 from '@/assets/img/partnership_img5@2x.jpg';
import partnership6 from '@/assets/img/partnership_img6@2x.jpg';
import { Article } from '@/components/Article/types';
import Button from '@/components/Button';
import PartnerCard from '@/components/Card/PartnerCard';
import ThumbnailCardSample from '@/components/Card/ThumbnailCardSample';
import Container from '@/components/Container';
import ContributionLogo, {
  CategoryData,
  PartnerData,
} from '@/components/ContributionLogo';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { BOARD_KIND_PARTNER } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const partnerCard = [
  {
    id: 1,
    headerTit: `글로벌 파트너십`,
    headerTitEn: `Global Partnership`,
    headerDec: `유니세프의 글로벌 캠페인에 기업이 스폰서십으로 참여`,
    category: `빅히트 뮤직`,
    title: `방탄소년단과 함께하는 러브 마이셀프 (LOVE MYSELF) 캠페인`,
    link: `/`,
    img: partnership1,
    date: `2017. 11 ~ 현재`,
    dec: [
      `빅히트 뮤직 및 소속 아티스트 후원금, 음반 및 캠페인 공식 굿즈 판매 수익금으로 유니세프 글로벌 아동 및 청소년 폭력 근절 캠페인 #ENDviolence 지원`,
    ],
  },
  {
    id: 2,
    headerTit: `해외지정사업`,
    headerTitEn: `Earmarked Programmes`,
    headerDec: `유니세프한국위원회, 개발도상국 유니세프국가사무소, 파트너십 기업·단체 간 3자 협약을 통해 특정 국가 및 사업분야 지정`,
    category: `BGF리테일`,
    title: `지역사회 기반의 아동친화도서관 3개년 프로젝트`,
    link: `/`,
    img: partnership2,
    date: `3년 (2017 ~ 2019)`,
    dec: [
      `베트남 잘라이(Gia Lai) 성 지역 유니세프 아동친화도서관 리뉴얼 사업지원`,
    ],
  },
  {
    id: 3,
    headerTit: `공익연계마케팅`,
    headerTitEn: `Cause Related Marketing(CRM)`,
    headerDec: `기업의 판매나 마케팅 또는 각종 프로모션 활동을 유니세프 후원과 연계`,
    category: `CJ제일제당`,
    title: `생수(미네워터) 프로모션 활동`,
    link: `/`,
    img: partnership3,
    date: ``,
    blit: true,
    dec: [
      `소비자가 생수 구매 시 물방울 모양의 바코드를 스캔하면 소비자가 100원, CJ제일제당이 100원 기부`,
      `총 2억 6천만 원 기금 후원`,
      `미네워터 매출 전년 대비 200% 상승 및 소비자 기부율 50%`,
    ],
  },
  {
    id: 4,
    headerTit: `사랑의 동전모금 `,
    headerTitEn: `Change for Good®`,
    headerDec: `모금함, 모금봉투 등을 통해 항공기, 편의점, 금융기관 등에서 진행하는 동전 모금 활동`,
    category: `아시아나항공`,
    title: `사랑의 동전 모으기 캠페인`,
    link: `/`,
    img: partnership4,
    date: `1994 ~ 현재`,
    dec: [
      `기내에서 모금활동을 진행하고 전 세계 어린이 권익 보호를 위한 유니세프의 다양한 사업에 사용`,
    ],
  },
  {
    id: 5,
    headerTit: `착한상품(라이센싱 제휴)`,
    headerTitEn: ` Licensing`,
    headerDec: `유니세프 로고를 활용한 상품 제작 라이센싱 계약을 통해 기금 모금`,
    category: `솜씨`,
    title: `상품 구매를 통한 기부문화 확산`,
    link: `/`,
    img: partnership5,
    date: `2014 ~ 현재`,
    dec: [
      `기업의 브랜드 가치를 높이고 경쟁력있는 상품을 만들어, 판매 수익금을 개발도상국 어린이를 위해 사용`,
    ],
    tip: `*술, 담배, 복권, 사행성 게임, 식품 등 유니세프 정신에 위배되거나안전이 염려되는 사업군은 제휴가 어렵습니다.`,
  },
  {
    id: 6,
    headerTit: `이벤트&스포츠 파트너십`,
    headerTitEn: `Events&Sports`,
    headerDec: `음악회, 전시회, 스포츠 행사 등 다양한 이벤트 또는 스포츠 구단/단체와의 파트너십을 활용`,
    category: `SM 엔터테인먼트`,
    title: `연말 자선행사 SMTOWN GALA CHARITY NIGHT 개최`,
    link: `/`,
    img: partnership6,
    date: `2015 ~ 현재`,
    dec: [
      `다양한 기부 프로그램과 소속 아티스트의 재능기부를 통해 유니세프 사업을 지원하고, 연말 자선행사를 개최하여 조성된 기부금은 전액 베트남 아동 음악 교육을 위한 ‘SMIle for U’ 캠페인에 사용`,
    ],
  },
];

const processes = [
  {
    id: 1,
    title: `1. 문의 및 상담`,
    img: Processes1,
    desc: [
      {
        dec: `웹사이트`,
        link: `/involve/corporate-and-organization/social-contribution#sectionInquiry`,
      },
      {
        dec: `전화 / 이메일`,
      },
    ],
  },
  {
    id: 2,
    title: `2. 제안서 교류`,
    img: Processes2,
    desc: [
      {
        dec: `기업의 니즈를 반영한 가장 적합한 \n사회공헌 활동을 제안`,
      },
    ],
  },
  {
    id: 3,
    title: `3. 본부 승인`,
    img: Processes5,
    desc: [
      {
        dec: `가이드에 따라 내용 검토 \n필요 시 본부 직접 승인 절차 진행`,
      },
    ],
  },
  {
    id: 4,
    title: `4. 후원/제휴 진행`,
    img: Processes6,
    desc: [
      {
        dec: `필요 문서 상호 교환`,
      },
      {
        dec: `협약서 작성`,
      },
      {
        dec: `필요 시 전단실/협약식 진행`,
      },
    ],
  },
  {
    id: 5,
    title: `5. 기부금 영수증 발급`,
    img: Processes3,
    desc: [
      {
        dec: `웹사이트 문의`,
      },
      {
        dec: `전화 / 이메일`,
      },
    ],
  },
  {
    id: 6,
    title: `6. 후원 리포트 제공`,
    img: Processes4,
    desc: [
      {
        dec: `지속적인 사업 모니터링 후\n후원 결과 리포트를 제공`,
      },
    ],
  },
];

const SectionProcess = styled(Section)`
  background: #f8f9fa;

  .process-ol {
    & > li {
      width: 33.33%;
      margin-top: 96px;
      margin-bottom: 0;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0;
      }
    }
    & > li:nth-child(3) {
      dt:after {
        display: none;
      }
    }
    dd {
      li {
        margin-bottom: 0;
        line-height: 2;
      }
    }

    ${breakpoint(`tablet`)} {
      & > li {
        width: 50%;
        margin-top: 72px;

        &:nth-child(3) {
          margin-top: 72px;
          dt:after {
            display: block;
          }
        }
        &:nth-child(2n + 0) {
          dt:after {
            display: none;
          }
        }
      }
    }
  }
`;

const SectionPartner = styled(Section)`
  .SectionHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .TabContainer {
    margin-top: 64px;
  }

  .list-item {
    width: 20%;
  }

  ${breakpoint(`tablet`)} {
    padding-top: 120px !important;
    .list-item {
      width: 25%;
    }
  }

  ${breakpoint(`mobile`)} {
    h2 {
      margin-bottom: 72px;
    }
    .list-item {
      width: 50%;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

// 한 페이지당 보여줄 아이템 수
const pagePerCount = 8;

const PartnerShip: React.FC<PageProps> = ({ location }) => {
  const [categories, setCategories] = useState<CategoryData[]>([]);
  const [partners, setPartners] = useState<PartnerData[]>([]);

  const loadCategories = useCallback(async () => {
    try {
      const {
        data: organizationCategory,
      } = await CorporateControllerService.getCsrCategoryListUsingGet();
      setCategories(
        organizationCategory.map((category: CsrVo) => ({
          id: category.indexNumber,
          categoryCodeName: category.csrCate1,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadPartners = useCallback(async () => {
    try {
      const {
        data: organizationLogo,
      } = await CorporateControllerService.getCsrLogoListUsingGet();
      setPartners(
        organizationLogo.map((logo: CsrVo) => ({
          id: logo.indexNumber,
          indexNumber: logo.indexNumber,
          title: logo.csrName,
          category1: logo.csrCate1,
          category2: logo.csrCate2,
          url: logo.csrSite,
          year: logo.csrYear,
          desc: logo.csrDesc,
          imgPath: logo.logoAttGrpNo,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 현재 페이지
  const [page, setPage] = useState(1);
  // 전체 글 수 (현재 조건기준)
  const [total, setTotal] = useState(0);
  const [newsLists, setNewsLists] = useState<Article[]>([]);

  const hasMore = useMemo(() => total > pagePerCount * page, [page, total]);

  const loadItem = useCallback(
    () =>
      BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_PARTNER],
        pagePerCount,
        page,
      })
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .then(({ data, totalCount }) => {
          setNewsLists((prevEvents) => [...prevEvents, ...data]);
          setTotal(totalCount);
        })
        .catch((e) => {
          console.error(e);
        }),
    [page],
  );

  useEffect(() => {
    loadItem();
    loadCategories();
    loadPartners();
  }, [loadCategories, loadItem, loadPartners]);

  // 다음 페이지 로드
  const loadNextPage = () => {
    setPage(page + 1);
  };

  return (
    <LayoutWithTitle
      location={location}
      title="파트너십"
      description="for every child, partnerships"
    >
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  유니세프의 파트너가 되어
                  <br />
                  어린이를 위한 다양한 활동에
                  <br />
                  함께해 주세요
                </PageComponent>
              </Tit>
            </h2>
          </SectionHeader>
          <PartnerCard itemData={partnerCard} />
        </Container>
      </Section>

      <SectionProcess className="by-sub-main-layout">
        <Container>
          <SectionHeader>
            <h2>
              <Tit size="s1">후원 및 제휴 프로세스</Tit>
            </h2>
          </SectionHeader>
          <ProcessList itemData={processes} arrow className="ProcessList" />
        </Container>
      </SectionProcess>

      <SectionPartner className="by-sub-main-layout">
        <Container
          css={`
            position: relative;
          `}
        >
          <SectionHeader
            className="SectionHeader"
            css={`
              ${breakpoint(640)} {
                padding-bottom: 0;
              }
            `}
          >
            <h2
              css={`
                ${breakpoint(640)} {
                  margin-bottom: 0;
                }
              `}
            >
              <Tit size="s1">함께하는 기업·기관</Tit>
            </h2>
          </SectionHeader>
          <ContributionLogo partners={partners} categories={categories} />
        </Container>
      </SectionPartner>
      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader>
            <h2>
              <Tit size="s1">파트너십 소식</Tit>
            </h2>
          </SectionHeader>
          <ThumbnailCardSample newsLists={newsLists} />
          {hasMore && (
            <ButtonContainer>
              <Button
                outline
                width={352}
                ico="moreArrow"
                onClick={() => {
                  loadNextPage();
                }}
              >
                <span className="ico">더 보기</span>
              </Button>
            </ButtonContainer>
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default PartnerShip;
